<template>
  <div class="d-flex flex-column vh-100">
    <sticky-header title="Rota" :subtitle="calendarDateTitle">
      <template #buttons>
        <b-button class="d-none d-md-inline-block ml-2" @click.prevent="calView('timeGridDay')" variant="outline-primary">Day</b-button>
        <b-button class="d-none d-md-inline-block ml-2" @click.prevent="calView('timeGridWeek')" variant="outline-primary">Week</b-button>
        <b-button class="d-none d-md-inline-block ml-2" @click.prevent="calPrev" variant="outline-primary"><fa-icon icon="angle-left"/></b-button>
        <b-button class="d-none d-md-inline-block ml-2" @click.prevent="calToday" variant="outline-primary">Today</b-button>
        <b-button class="d-none d-md-inline-block ml-2" @click.prevent="calNext" variant="outline-primary"><fa-icon icon="angle-right"/></b-button>
      </template>
    </sticky-header>
    <div class="bg-light border-bottom d-md-none d-flex flex-row justify-content-end px-4 py-2">
      <b-button class="ml-2" @click.prevent="calPrev" variant="outline-primary"><fa-icon icon="angle-left"/></b-button>
      <b-button class="ml-2" @click.prevent="calToday" variant="outline-primary">Today</b-button>
      <b-button class="ml-2" @click.prevent="calNext" variant="outline-primary"><fa-icon icon="angle-right"/></b-button>
    </div>
    <div class="flex-fill p-3">
      <div class="d-flex flex-column mt-5 py-3 justify-content-center" v-if="loading">
        <b-spinner class="align-self-center" variant="primary" />
        <p class="align-self-center mt-1 mb-0">Loading...</p>
      </div>
      <full-calendar ref="calendar" :options="calendarOptions" v-if="!loading" />
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import map from 'lodash/map'
import FullCalendar from '@fullcalendar/vue'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import bootstrapPlugin from '@fullcalendar/bootstrap'
import StickyHeader from '../components/StickyHeader.vue'
import { mapActions, mapState } from 'vuex'

export default {
  components: { FullCalendar, StickyHeader },
  computed: {
    ...mapState('maintenanceVisits', ['data', 'loading']),
    ...mapState('auth', ['user'])
  },
  async mounted() {
    this.calToday();
  },
  data() {
    return {
      allEvents: [],
      date: sessionStorage.getItem("maintenance_calendar:date") ? moment(sessionStorage.getItem("maintenance_calendar:date")) : moment(),
      selectedDates: sessionStorage.getItem("maintenance_calendar:selectedDates") ?? {
        start: moment(),
        end: moment(),
      },

      dayView: false,

      calendarDateTitle: 'Loading',
      calendarOptions: {
        // Plugins, Themes and View
        plugins: [ bootstrapPlugin, timeGridPlugin, interactionPlugin ],
        businessHours: [{
          daysOfWeek: [0, 1, 2, 3, 4, 5 ,6],
          startTime: '09:00',
          endTime: '17:00',
        }],
        initialView: 'timeGridWeek',
        themeSystem: 'bootstrap',
        headerToolbar: false,
        nowIndicator: true,
        allDaySlot: false,
        weekends: true,
        height: "100%",

        editable: true,
        selectable: false,
        selectMirror: true,
        events: [],
        eventsDaily: [],

        defaultDate: this.date,
        eventDrop: this.calEventDrop,

        eventClick: this.onClickEvent,

        dayHeaderContent(date) {
          return moment(date.date).format('ddd DD/MM')
        }
      }
    }
  },
  methods: {
    ...mapActions('maintenanceVisits', ['fetch', 'updateOrCreate']),
    moment,
    calNext() {
      this.date = this.date.add(1, (this.calendarOptions.initialView == 'timeGridDay') ? 'day' : 'week');

      sessionStorage.setItem("rota:date", this.date)
      this.load(this.date).then(() => {
        this.calendarDateTitle = this.date.format('MMMM YYYY');
        this.$refs.calendar.getApi().gotoDate(this.date.toDate());
      });
    },
    calPrev() {
      this.date = this.date.add(-1, (this.calendarOptions.initialView == 'timeGridDay') ? 'day' : 'week');

      sessionStorage.setItem("rota:date", this.date)
      this.load(this.date).then(() => {
        this.calendarDateTitle = this.date.format('MMMM YYYY');
        this.$refs.calendar.getApi().gotoDate(this.date.toDate());
      });
    },
    calToday() {
      this.date = moment();

      sessionStorage.setItem("rota:date", this.date)
      this.load(this.date).then(() => {
        this.calendarDateTitle = this.date.format('MMMM YYYY');
        this.$refs.calendar.getApi().gotoDate(this.date.toDate());
      });
    },
    calView(view) {
      this.dayView = (view == 'timeGridDay');

      this.calendarOptions.initialView = view;
      this.$refs.calendar.getApi().changeView(view);
    },
    async load(date) {
      const after = moment(date).startOf('week').format('YYYY-MM-DD');
      const before = moment(date).endOf('week').format('YYYY-MM-DD');
      const userId = this.user.type !== 'staff' ? this.user.id : null;

      await this.fetch({ after, before, userId });

      return;
    },
    onClickEvent(info) {
      this.$router.push('/maintenance/details/' + info.event.extendedProps.maintenance.id);
    },
    async calEventDrop(info) {
      const maintenance = info.event.extendedProps.maintenance
      const visit = maintenance.visits.find(e => e.id === parseInt(info.event.id))


      if (visit) {
        await this.updateOrCreate({
          ...visit,
          visit_at: moment(info.event.start).format('YYYY-MM-DD HH:mm:ss'),
          start_time: moment(info.event.start).format('HH:mm:ss'),
          end_time: moment(info.event.end).format('HH:mm:ss')
        })
      }
    }
  },
  watch: {
    async '$route'(newValue, value) {
      if (!value.meta.modalId && newValue.meta.modalId) {
        return this.$bvModal.show(newValue.meta.modalId);
      }

      return this.$bvModal.hide(value.meta.modalId);
    },
    data(data) {
      const events = map(data, visit => {
        return {
          id: visit.id,
          extendedProps: {
            maintenance: visit.maintenance
          },
          title: (visit?.maintenance?.maintenance_contractor?.company_name ?? visit?.maintenance?.user?.name ?? 'Unknown') + ' | ' + visit.maintenance.summary + ' - ' + visit.summary,
          start: moment(moment(visit.visit_at).format('YYYY-MM-DD') + ' ' + visit.start_time).toDate(),
          end: moment(moment(visit.visit_at).format('YYYY-MM-DD') + ' ' + visit.end_time).toDate(),
          allDay: false,

          backgroundColor: (visit.maintenance.status == 'High') ? '#c0392b' : (visit.maintenance.status == 'Medium') ? '#e67e22' : '#7f8c8d',
          borderColor: (visit.maintenance.status == 'High') ? '#c0392b' : (visit.maintenance.status == 'Medium') ? '#e67e22' : '#7f8c8d',
          textColor: 'white',
        }
      });

      this.calendarOptions = {
        ...this.calendarOptions,
        events: events,
      };
    },
    selectedDates(data) {
      sessionStorage.setItem("maintenance_calendar:date", data);
    },
    dayView(data) {
      sessionStorage.setItem("maintenance_calendar:dayView", data);
    }
  }
}
</script>

<style>
.fc-button {
  border-radius: 0;
}

.fc-button-primary {
  background: #1A1A1A;
}

.fc-timeGridDay-view .fc-timegrid-event-harness {
  max-width: 20% !important;
}

@media (min-width: 768px) {
  .dayview-width {
    overflow-x: auto;
    width: calc(100vw - 351px);
  }
}
@media (max-width: 768px) {
  .dayview-width {
    overflow-x: auto;
  }
}

</style>
